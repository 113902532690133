import React from 'react'
import Helmet from 'react-helmet'
import Navbar from '../components/Navbar'
import './all.sass'
import useSiteMetadata from './SiteMetadata'

const TemplateWrapper = ({
  page,
  lang,
  navbarTitle,
  navbarLinks,
  children
}) => {
  let { en, fr, dj } = useSiteMetadata()

  if (page === 'dj') {
    en = dj.en
    fr = dj.fr
  }

  const { title, description, url, image } = lang === 'en' ? en : fr

  return (
    <div className={page ? page : ''}>
      <Helmet>
        <html lang={lang} />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-16x16.png"
          sizes="16x16"
        />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:site_name" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={image} />

        <meta itemprop="name" content={title} />
        <meta itemprop="url" content={url} />

        <meta name="twitter:title" content={title} />
        <meta name="twitter:url" content={url} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:image" content={image} />
      </Helmet>
      <Navbar page={page} title={navbarTitle} links={navbarLinks} lang={lang} />
      <div>{children}</div>
    </div>
  )
}

export default TemplateWrapper
