import React from 'react'
import { Link } from 'gatsby'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: ''
    }
  }

  closeHamburger = () => {
    if (this.state.active) {
      this.toggleHamburger()
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active has-background-black-transparent'
            })
          : this.setState({
              navBarActiveClass: ''
            })
      }
    )
  }

  render() {
    const { lang, page, title, links } = this.props

    let linkPrefix = ''
    if (lang === 'fr') {
      linkPrefix = '/'
    } else if (lang === 'en') {
      linkPrefix = '/en'
    }

    if (page === 'dj') {
      linkPrefix = ('/dj' + linkPrefix).replace(/\/\s*$/, '')
    }

    return (
      <nav
        className="navbar is-spaced is-fixed-top has-background-black-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to={linkPrefix} className="navbar-item">
              <h1 className="title is-3 has-text-white">{title}</h1>
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass} has-text-white`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-start has-text-centered">
              {links.map(link => (
                <Link
                  key={link.anchor}
                  className="navbar-item has-text-white underline"
                  to={`${linkPrefix}${link.anchor}`}
                  onClick={() => this.closeHamburger()}
                >
                  {link.text}
                </Link>
              ))}
              {page === 'dj' ? (
                <Link
                  className="navbar-item has-text-white underline piano-link"
                  to={`/${lang === 'en' ? 'en' : ''}`}
                  onClick={() => this.closeHamburger()}
                >
                  Piano
                </Link>
              ) : (
                <Link
                  className="navbar-item has-text-white underline dj-link"
                  to={`/dj${lang === 'en' ? '/en' : ''}`}
                  onClick={() => this.closeHamburger()}
                >
                  DJ
                </Link>
              )}
            </div>
            <div className="navbar-end has-text-centered has-text-white is-paddingless">
              <div className="language-selection navbar-item">
                <Link
                  className={`has-text-white ${
                    lang === 'fr' ? 'underline-permanent' : 'underline'
                  }`}
                  to={`${page === 'dj' ? '/dj' : ''}/`}
                >
                  fr
                </Link>
                <span>/</span>
                <Link
                  className={`has-text-white ${
                    lang === 'en' ? 'underline-permanent' : 'underline'
                  }`}
                  to={`${page === 'dj' ? '/dj' : ''}/en`}
                >
                  en
                </Link>
              </div>
              <div className="social">
                <a
                  className="navbar-item"
                  href="https://www.facebook.com/people/Steve-Rumo/100002203043957"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="icon has-text-white">
                    <span className="icon is-large">
                      <i className="mdi mdi-facebook" />
                    </span>
                  </span>
                </a>
                <a
                  className="navbar-item"
                  href="https://open.spotify.com/artist/5B6f7xCsF07ETQNxiOveaJ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="icon has-text-white">
                    <span className="icon is-large">
                      <i className="mdi mdi-spotify" />
                    </span>
                  </span>
                </a>
                <a
                  className="navbar-item"
                  href="https://music.apple.com/fr/artist/steve-rumo/514188891"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="icon has-text-white">
                    <span className="icon is-large">
                      <i className="mdi mdi-itunes" />
                    </span>
                  </span>
                </a>
                <a
                  className="navbar-item"
                  href="https://www.youtube.com/channel/UCKS1y4UUmElyHfzc1DA1O4g"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="icon has-text-white">
                    <span className="icon is-large">
                      <i className="mdi mdi-youtube" />
                    </span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
