import { graphql, useStaticQuery } from 'gatsby'

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            en {
              title
              description
              url
              image
            }
            fr {
              title
              description
              url
              image
            }
            dj {
              en {
                title
                description
                url
                image
              }
              fr {
                title
                description
                url
                image
              }
            }
          }
        }
      }
    `
  )
  return site.siteMetadata
}

export default useSiteMetadata
